.Post {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    min-height: 100vh;
}

.edit {
    font-family: 'Inter', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    text-align: left;
    color: #ffffff;
    border: none;
    background-color: #ffffff44;
    padding: 8px;
    margin: 0;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-right: 8px;
    text-decoration: none;
}

.delete {
    font-family: 'Inter', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    text-align: left;
    color: #ffffff;
    border: none;
    background-color: #ffffff44;
    padding: 8px;
    margin: 0;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-right: 8px;
    text-decoration: none;
    transition: 0.5s;
}

.delete:hover {
    background-color: #ff6969ff;
}

.embed {
    border: none;
    border-radius: 10px;
    margin: 0;
    margin-top: 16px;
    width: 100%;
    height: 152px;
}

.post-container {
    max-width: 600px;
    width: 100%;
    margin: 50px;
}

.toggle-comments {
    margin-right: 10px;
}
