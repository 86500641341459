@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
    margin: 0;
    background-color: #000000;
    color: #ffffff;
    font-family: 'Inter', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button {
    font-family: 'Inter', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    text-align: left;
    color: #000000;
    border: none;
    background-color: #ffffff;
    padding: 8px;
    margin: 0;
    margin-top: 4px;
    margin-bottom: 4px;
    box-sizing: border-box;
}

p {
    font-family: 'IBM Plex Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
    padding: 0;
    margin: 0;
    font-size: 36px;
}

hr {
    border: 0;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: #ffffff33;
    display: block;
    border-radius: 1px;
}

input {
    font-family: 'IBM Plex Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-size: 16px;
    color: #ffffff;
    border: none;
    background-color: #ffffff20;
}

input:focus {
    outline: none;
    border: none;
}

textarea {
    font-family: 'IBM Plex Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-size: 16px;
    color: #ffffff;
    border: none;
    background-color: #ffffff20;
}

textarea:focus {
    outline: none;
    border: none;
}

.date {
    font-size: 12px;
    opacity: 0.3;
}

.error {
    color: #ff5050;
}
