h3 {
    margin-top: 0;
}

.Comments {
    padding: 24px;
    background-color: #ffffff11;
}

.comment-field {
    width: 100%;
    min-height: 58px;
    resize: vertical;
    padding: 8px;
    margin: 0;
    box-sizing: border-box;
    margin-top: 12px;
}

.comment-container {
    font-size: 14px;
}

.comment-container > p {
    margin-top: 8px;
    margin-bottom: 8px;
}

.counter {
    opacity: 0.5;
    margin-bottom: 8px;
}

.loadMore {
    margin-top: 20px !important;
}

.name-container {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
}

.name-container > h4 {
    margin: 0;
    margin-top: 22px;
}

.name-container > .delete {
    background: none;
    color: #ffffff;
    transition: 0.5s;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: bottom;
    padding: 0;
    line-height: 14px;
}

.name-container > .delete:hover {
    color: #ff6969;
    background: none;
}

.name-field {
    font-family: 'Inter', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    width: 100%;
    padding: 8px;
    margin: 0;
    box-sizing: border-box;
}

.submit-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 14px;
}
