.Home {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    min-height: 100vh;
}

.link {
    font-family: 'Inter', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    text-align: left;
    color: #000000;
    border: none;
    background-color: #ffffff;
    padding: 8px;
    margin: 0;
    margin-top: 4px;
    margin-bottom: 4px;
    width: 100px;
    text-decoration: none;
    box-sizing: border-box;
}

.panel {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 0;
}
