.login {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    min-height: 100vh;
}

.login-field {
    padding: 8px;
    margin: 0;
    margin-top: 4px;
    margin-bottom: 4px;
}

.panel {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 0;
}
