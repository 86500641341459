.CreateEditPost {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    min-height: 100vh;
}

.submit {
    margin-top: 24px;
}

.create-edit-container {
    max-width: 600px;
    width: 100%;
}

.content-field {
    width: 100%;
    min-height: 120px;
    resize: vertical;
    padding: 8px;
    margin: 0;
    margin-top: 12px;
    box-sizing: border-box;
}

.embed_url-field {
    width: 100%;
    resize: vertical;
    padding: 8px;
    margin: 0;
    margin-top: 12px;
    box-sizing: border-box;
}


.title-field {
    font-family: 'Inter', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    width: 100%;
    margin: 0;
    margin-top: 12px;
    font-size: 24px;
    font-weight: bold;
    padding: 8px;
    box-sizing: border-box;
}
