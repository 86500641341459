.DeletePost {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    min-height: 100vh;
}

.confirmDelete {
    background-color: #ff696944;
    color: #ffffff;
    transition: 0.5s;
}

.confirmDelete:hover {
    background-color: #ff6969;
}

.cancel {
    font-family: 'Inter', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    text-align: left;
    color: #000000;
    border: none;
    background-color: #ffffff;
    padding: 8px;
    margin: 0;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-right: 8px;
    text-decoration: none;
}
