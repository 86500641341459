.BlogList {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    min-height: 100vh;
}

.content-slice-container {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
}

.new-post {
    font-family: 'Inter', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    text-align: left;
    color: #000000;
    border: none;
    background-color: #ffffff;
    padding: 8px;
    text-decoration: none;
    margin-top: 12px;
    box-sizing: border-box;
    display: inline-block;
}

.preview-container > p {
    opacity: 0.5;
}

.ellipsis {
    text-decoration: none;
    color: #ffffff;
    opacity: 0.5;
    transition: 0.5s;
}

.ellipsis:hover {
    opacity: 1;
}

.list-container {
    max-width: 600px;
    width: 100%;
}

.post-title-link {
    text-decoration: none;
    color: #ffffff
}
